.App {
  text-align: center;
}

.App-header {
  user-select: none; /* Prevents text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite linear; /* Removed specific duration */
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {

}




.App-header {
  background-color: #3e474b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #d9dbdf;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.share-link-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #f0f0f0;
  text-align: center;
  z-index: 1000;
}

.share-link a {
  color: #f0f0f0;
  text-decoration: none;
}

.share-link a:hover {
  text-decoration: underline;
}

.photos-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  margin: 0 auto;
}

.photo-item {
  width: 100%;
  max-width: 300px;
  margin: 10px;
}

.photo-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

@media (min-width: 600px) {
  .photo-item {
    width: calc(50% - 20px);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #f0f0f0;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.share-input {
  width: 100%;
  margin: 10px 0;
}

button {
  margin: 5px;
}

footer {
  background-color: #3e474b;
  padding: 1rem 0;
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}

footer p {
  margin: 0;
  color: #fff;
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}

.full-screen-photo {
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
}

